body{background: url(../images/pattern.png); font-family: 'Open Sans'; font-size: 13px; line-height: 2;}
h1,h2,h3,h4 { font-family: 'Lato'; color: #af0606;}
h1 { font-size: 26px;}
h2 { color: #005692; font-size: 22px;}
.top-nav{ background:#3097d1; font-family: 'Lato'; color: #FFF; line-height: 32px; font-size: 13px;line-height: 36px;}
.top-call{background: url(../images/call.png) no-repeat; padding: 0 0 0 6.5rem; font-size: 15px;  }
.top-num-tel a { color: #FFF; text-decoration: underline; }
.top-num-tel { text-align: right;}
.or { display: inline-block; padding-left: 25%; }
.recall { line-height: 33px; }
.recall .input-group input.form-control { font-family: 'Lato'; font-size: 13px; }
.recall .input-group input.form-control  { height: 28px; line-height: 16px; }
.recall .input-group .btn.form-btn { height: 26px; line-height: 15px; }
.header{ margin-top :20px; margin-bottom:10px;}
.logo img { margin-top: 8px; }
.corps{background-color:#FFF;box-shadow: 0px 0px 5px 1px rgba(158,152,158,0.5);padding:15px 20px; margin-top:15px; padding-bottom: 40px;}
.form-tele{ color:#e2e2e2; font-family:'lato'; font-size:12px;}
.form-btn{color: #ffffff;background-color:#116199;border-color:#1c73a5; margin-left:-10px; font-family:'lato'; font-size:13px; height:35px; vertical-align: top; -webkit-box-shadow: 0px 2px 0px #012b63;
        -moz-box-shadow: 0px 2px 0px #012b63;
        box-shadow: 0px 2px 0px #012b63;}
.form-btn:hover { color: #dff3ff; }
.ba{ background-color: #116199;}
.picto{ margin:30px 0 15px 0;}
.picto img { max-width: 100%;}
.col{ background-color:#FFF;}
.col-margin{ margin-bottom:10px;}
.form-border{ margin-top:5px; width:280px;}
.footer{ background-color:#2792da;}
.footer-container{ background-color:#005692;margin:-15px  15px 0px;min-height:100px; padding:20px 0;}
.footer-p{ color:#bbdef5; font-family: 'Open Sans';font-size: 13px;font-style:normal;}
.footer-a{color:#bbdef5; font-family: 'Open Sans';font-size: 13px;text-decoration: underline; margin-left:25px;}
.footer-a:hover{color:#ffffff;}
.footer-cal{font-family:'lato';font-weight:bold;font-size:24px; color:#FFF;}
.copyright{color:#388cc5;}
.img-call{ margin-right:10px;}
.nav.navbar-nav { font-family: 'Lato'; font-size: 22px; }
.navbar-nav>li { padding: 10px 0; }
.navbar-nav>li>a { padding: 5px 20px; border-right: 1px solid #669abe;}
.nav.navbar-nav li:last-child a { border:none;}
.form-devis { background: #F9F9F9; padding: 20px 0 25px;}
.label-devis { font-weight: bold; text-align: right;}
.form-inline .checkbox { display: block;}
.form-inline .form-group { display: block;}
label {font-weight: normal;}
.main-container { padding-bottom: 20px;}
.form-group>.row { padding-bottom: 16px; }
input[type=radio] { margin-right: 4px; }
.btn-default.btn { background-color: #B00909; color: #FFF; font-weight: bold; text-transform: uppercase; padding: 6px 45px; font-size: 12px; border:1px solid #5E0606; }
.form-devis .glyphicon { color: #FFC8C8; padding-left: 5px;}
.form-rappel .row { margin-bottom: 15px; }
.form-top {
    overflow: hidden;
    padding: 0 25px 15px 25px;
    text-align: left;
    margin-top: 25px;
}

.form-top-left {
    float: left;
    width: 75%;
    padding-top: 25px;
}

.form-top-left h3 { margin-top: 0; font-size: 20px;}

.form-top-right {
    float: left;
    width: 25%;
    padding-top: 5px;
    font-size: 56px;
    line-height: 100px;
    text-align: right;
    color: #af0606;
}

.form-bottom {
    padding: 25px 25px 30px 25px;
    text-align: left;
}

.form-bottom form textarea {
    height: 100px;
}

.form-bottom form button.btn {
    width: 100%;
}

.form-bottom form .input-error {
    border-color: #de615e;
}
.fa.fa-btn.fa-sign-in { color: #FFF; padding-right: 10px; }
.nav-pills.nav-stacked>li>a {
    background: #D5EBF7;
    color: #005692;
    padding: 6px 15px;
}
.nav-pills.nav-stacked>li>a:hover {
    background: #ECF8FF;
}
.nav-pills.nav-stacked>li {
    padding: 3px 0;
}
.nav-pills.nav-stacked>li>a.current {
    background: #2792DA;
    color: #fff;
}
.dashboard .panel-heading { padding: 3px 15px;}
.dashboard .panel-title { font-size: 14px; font-weight: bold; }
.dashboard .panel-body { min-height: 210px;}

@media (max-width: 768px) {
    .nav.navbar-nav { background: #116199; border-radius: 4px;}
    .navbar-nav>li>a { border:none; color: #fff; padding: 0 10px; font-size: 15px;  }
    .navbar-nav>li>a:hover,.navbar-nav>li>a:active,.navbar-nav>li>a:visited,.navbar-nav>li>a:focus { background: none;}
    .navbar-nav>li { padding: 7px 0; }
    .navbar-nav>li:hover { background:#3097D1; }

    .top-call { width: 226px; margin: 0 auto; }
    .recall { width: 282px; margin: 0 auto;  }
    .form-border { width: initial; }
    .top-num-tel { text-align: center;}
    .or { display: none; }
    .logo img {max-width: 100%;}
    .header { margin-bottom: 0;}
}
@media (min-width: 768px) and (max-width: 992px) {
    .navbar-nav>li>a { padding: 10px; font-size: 18px; }
}